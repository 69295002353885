import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Input/Numeric.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Select/Token/Item.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Select/Token/List.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Dropdown.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withModifiers as _withModifiers, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "text-sm font-semibold text-gray-500 flex items-center justify-between px-4 mb-2" };
const _hoisted_2 = {
  key: 0,
  class: "text-right flex items-center gap-2"
};
const _hoisted_3 = { class: "px-4" };
const _hoisted_4 = { class: "flex justify-between" };
const _hoisted_5 = { class: "flex items-center gap-2" };
const _hoisted_6 = { class: "flex items-center justify-between gap-2 px-4" };
const _hoisted_7 = { class: "flex flex-wrap items-center gap-1 text-sm whitespace-nowrap" };
const _hoisted_8 = {
  key: 0,
  class: "text-red-500 capitalize"
};
import { BigNumberInWei } from "@injectivelabs/utils";
import { TradeField } from "@/types";
import { ONE_IN_BASE } from "@/app/utils/constants";
const __default__ = {
  inheritAttrs: false
};
export default /* @__PURE__ */ _defineComponent({
  ...__default__,
  __name: "Index",
  props: {
    hideMax: Boolean,
    disabled: Boolean,
    required: Boolean,
    inputDisabled: Boolean,
    denom: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    maxDecimals: {
      type: Number,
      default: 6
    },
    amountFieldName: {
      type: String,
      default: TradeField.BaseAmount
    },
    additionalRules: {
      type: Object,
      default: void 0
    }
  },
  emits: ["update:denom", "update:amount", "update:max"],
  setup(__props, { emit }) {
    const props = __props;
    const selectedToken = computed(
      () => props.options.find(({ denom }) => denom === props.denom)
    );
    const selectedTokenBalance = computed(
      () => selectedToken.value ? new BigNumberInWei(selectedToken.value.balance).toBase(
        selectedToken.value.token.decimals
      ) : "0"
    );
    const inputPlaceholder = computed(
      () => ONE_IN_BASE.shiftedBy(-props.maxDecimals).toFixed()
    );
    const {
      valueToBigNumber,
      valueToFixed: maxBalanceToFixed,
      valueToString: maxBalanceToString
    } = useBigNumberFormatter(selectedTokenBalance, {
      decimalPlaces: props.maxDecimals
    });
    const {
      value: amount,
      errors: amountErrors,
      setValue: setAmountValue
    } = useStringField({
      name: props.amountFieldName,
      rule: "",
      dynamicRule: computed(() => {
        if (!props.required) {
          return "";
        }
        return `insufficientBalance:${maxBalanceToFixed.value}|required`;
      })
    });
    const denomValue = computed({
      get: () => props.denom || "",
      set: (denom) => {
        if (denom) {
          emit("update:denom", denom);
        }
      }
    });
    function handleAmountUpdate(amount2) {
      setAmountValue(amount2);
      emit("update:amount", {
        amount: amount2,
        isBaseAmount: props.amountFieldName === TradeField.BaseAmount
      });
    }
    function handleMax() {
      emit("update:max", { amount: maxBalanceToFixed.value });
    }
    return (_ctx, _cache) => {
      const _component_AppInputNumeric = __nuxt_component_0;
      const _component_AppSelectTokenItem = __nuxt_component_1;
      const _component_BaseIcon = __nuxt_component_2;
      const _component_AppSelectTokenList = __nuxt_component_3;
      const _component_BaseDropdown = __nuxt_component_4;
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["bg-gray-1000 rounded-xl py-4", {
          "border-red-500 border": _unref(amountErrors).length > 0
        }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default"),
          _unref(selectedToken) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _unref(valueToBigNumber).gt(0) && !__props.hideMax ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "cursor-pointer text-blue-500 hover:text-opacity-80",
              onClick: handleMax
            }, _toDisplayString(_ctx.$t("trade.max")) + ": ", 1)) : _createCommentVNode("", true),
            _createElementVNode("p", null, _toDisplayString(_unref(maxBalanceToString)) + " " + _toDisplayString(_unref(selectedToken).token.symbol), 1)
          ])) : _createCommentVNode("", true)
        ]),
        _createVNode(_component_BaseDropdown, {
          class: "w-full",
          disabled: __props.disabled || __props.options.length <= 1,
          distance: _unref(amountErrors).length > 0 ? 44 : 24,
          flip: false,
          "auto-size": true,
          placement: "bottom",
          "auto-boundary-max-size": "",
          "popper-class": "dropdown"
        }, {
          content: _withCtx(({ close }) => [
            _createVNode(_component_AppSelectTokenList, {
              modelValue: _unref(denomValue),
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => _isRef(denomValue) ? denomValue.value = $event : null),
              close,
              balances: __props.options
            }, null, 8, ["modelValue", "close", "balances"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_AppInputNumeric, {
                  modelValue: _unref(amount),
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event) => _isRef(amount) ? amount.value = $event : null),
                    handleAmountUpdate
                  ],
                  sm: "",
                  "no-padding": "",
                  "transparent-bg": "",
                  "input-classes": "p-0 text-xl font-bold",
                  "max-decimals": __props.maxDecimals,
                  placeholder: _unref(inputPlaceholder),
                  disabled: __props.disabled || !_unref(selectedToken) || __props.inputDisabled,
                  onClick: _cache[1] || (_cache[1] = _withModifiers(() => {
                  }, ["stop"]))
                }, null, 8, ["modelValue", "max-decimals", "placeholder", "disabled"]),
                _createElementVNode("div", _hoisted_5, [
                  _unref(selectedToken) ? (_openBlock(), _createBlock(_component_AppSelectTokenItem, {
                    key: 0,
                    token: _unref(selectedToken).token
                  }, null, 8, ["token"])) : _createCommentVNode("", true),
                  !__props.disabled && __props.options.length > 1 ? (_openBlock(), _createBlock(_component_BaseIcon, {
                    key: 1,
                    name: "caret-down-slim",
                    sm: ""
                  })) : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["disabled", "distance"]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _unref(amountErrors).length > 0 ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(amountErrors)[0]), 1)) : _createCommentVNode("", true)
          ])
        ])
      ], 2);
    };
  }
});
