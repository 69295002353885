import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "flex items-center gap-2" };
const _hoisted_2 = { key: 0 };
export default /* @__PURE__ */ _defineComponent({
  __name: "Item",
  props: {
    sm: Boolean,
    xl: Boolean,
    showBalance: Boolean,
    token: {
      type: Object,
      required: true
    },
    balance: {
      type: String,
      default: ""
    }
  },
  emits: ["click"],
  setup(__props, { emit }) {
    const props = __props;
    const classes = computed(() => {
      if (props.sm) {
        return "text-sm";
      }
      if (props.xl) {
        return "text-xl";
      }
      return "text-base";
    });
    const { valueToString: balanceToString } = useBigNumberFormatter(
      computed(() => props.balance)
    );
    function handleClick() {
      emit("click", props.token.denom);
    }
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", {
        class: "flex items-center justify-between",
        onClick: handleClick
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_CommonTokenIcon, { token: __props.token }, null, 8, ["token"]),
          _createElementVNode("span", {
            class: _normalizeClass(["font-semibold max-w-2xs truncate", _unref(classes)])
          }, _toDisplayString(__props.token.symbol), 3)
        ]),
        __props.showBalance ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(balanceToString)), 1)) : _createCommentVNode("", true)
      ]);
    };
  }
});
