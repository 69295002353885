import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Input/Index.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Select/Token/Item.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "max-h-xs px-4 pt-4 pb-4" };
const _hoisted_2 = { class: "mb-2 text-white" };
import { BigNumberInBase, BigNumberInWei } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "List",
  props: {
    showBalance: Boolean,
    close: {
      type: Function,
      required: true
    },
    balances: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: String,
      default: ""
    }
  },
  emits: ["update:modelValue"],
  setup(__props, { emit }) {
    const props = __props;
    const search = ref("");
    const filteredOptions = computed(() => {
      return props.balances.filter((balance) => {
        const formattedSearch = search.value.trim().toLowerCase();
        if (!formattedSearch) {
          return true;
        }
        return balance.token.symbol.toLowerCase().startsWith(formattedSearch);
      });
    });
    const sortedBalances = computed(() => {
      if (!props.showBalance) {
        return filteredOptions.value;
      }
      return filteredOptions.value.sort(
        (b1, b2) => new BigNumberInBase(b2.balance).minus(b1.balance).toNumber()
      );
    });
    const sortedBalancesWithBalancesToBase = computed(() => {
      return sortedBalances.value.map((balance) => {
        return {
          ...balance,
          balance: new BigNumberInWei(balance.balance).toBase(balance.token.decimals).toFixed()
        };
      });
    });
    function handleClick(denom) {
      emit("update:modelValue", denom);
      props.close();
    }
    return (_ctx, _cache) => {
      const _component_AppInput = __nuxt_component_0;
      const _component_AppSelectTokenItem = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppInput, {
            modelValue: _unref(search),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(search) ? search.value = $event : null),
            sm: "",
            placeholder: _ctx.$t("common.search")
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedBalancesWithBalancesToBase), (balance) => {
          return _openBlock(), _createBlock(_component_AppSelectTokenItem, _mergeProps({
            sm: true,
            token: balance.token,
            balance: balance.balance,
            showBalance: true
          }, {
            key: balance.denom,
            class: "px-2 py-3 hover:bg-blue-500 cursor-pointer rounded text-white hover:text-black",
            onClick: handleClick
          }), null, 16);
        }), 128))
      ]);
    };
  }
});
